import { Number } from 'aws-sdk/clients/iot'
import Rest from '../Rest'

export default class ContentQuestionnaireService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/content_questionnaires'

  static build(vs: any = null, baseUrl) {
    return new this(this.resource, null, vs, baseUrl)
  }

  uploadOptionImageUrl(id: Number, params: Object) {
    return this.post(`/upload_option_image_url/${id}`, params).then(data => {
      return data
    })
  }

  canAnswer(questionnaireId: Number, preview: Boolean = false) {
    return this.get(`/${questionnaireId}/can_answer`, {
      preview: preview
    }).then(data => {
      return data
    })
  }

  resume(questionnaireId: Number) {
    return this.get(`/${questionnaireId}/resume`).then(data => {
      return data
    })
  }

  generalResume(questionnaireId: Number) {
    return this.get(`/${questionnaireId}/resume/general`).then(data => {
      return data
    })
  }

  createEmailsSent(questionnaireId: Number, payload: Object) {
    return this.post(`/${questionnaireId}/emails`, payload).then(
      data => {
        return data
      }
    )
  }

  destroyEmailSent(questionnaireId: Number, emailSentId: Number) {
    return this.delete(`/${questionnaireId}/emails/${emailSentId}`).then(
      (data) => {
        return data
      }
    )
  }

  selectAllEmails(questionnaireId: Number, type) {
    return this.get(`/${questionnaireId}/emails/${type}/selection`)
  }

  sendEmailToAll(questionnaireId: Number, params: any) {
    return this.post(`/${questionnaireId}/send_all_email`, params).then(
      data => {
        return data
      }
    )
  }

  findByShortUrlId(shortUrlId: String) {
    return this.get(`/${shortUrlId}/find_by_short_url_id`).then(data => {
      return data
    })
  }

  scoreRelease(id: Number) {
    return this.post(`/${id}/score_release`).then(data => {
      return data
    })
  }

  clone(id: Number) {
    return this.post(`/${id}/clone`).then(data => {
      return data
    })
  }

  storeQuestion(form: any) {
    return this.put(`/${this.getId(form)}/store_question`, form).then(data => {
      return data
    })
  }

  storeSingleQuestion(form: any) {
    return this.put(`/${this.getId(form)}/store_single_question`, form).then(data => {
      return data
    })
  }

  getContent(id: Number) {
    return this.get(`/${id}/content`).then(data => {
      return data
    })
  }

  deleteOptionImage(id: Number, mediaId: Number) {
    return this.delete(`/${id}/media/${mediaId}`).then(data => {
      return data
    })
  }

  getParticipants(id: Number, level: Number) {
    return this.get(`/${id}/participants`, {level})
  }

  getStatusIndicators(id: Number, data: any) {
    return this.get(`/${id}/status`, data).then(data => data)
  }

  getEventSeverityChart(id: Number, data: any) {
    return this.get(`/${id}/severity_chart`, data).then(data => data)
  }

  getEventData(id: Number, data: any) {
    return this.get(`/${id}/events/data`, data)
  }

  calcAllQuestionScore(id: Number) {
    return this.post(`/${id}/calc_all_questions_score`)
  }

  reapplyCorrection(id: Number) {
    return this.post(`/${id}/reapply_correction`)
  }

  getContentSimple(id: Number) {
    return this.get(`/${id}/simple`).then(data => {
      return data
    })
  }

  getInvitedGrid(id: Number, level: Number) {
    return this.get(`/${id}/invited/grid`, {level})
  }

  resendInvite(id: Number, ids: Number) {
    const params = {
      'invite_ids': ids
    }
    return this.post(`/${id}/invited/resend_invite`, params).then(data => {
      return data
    })
  }

  resendInviteAll(id: Number) {
    return this.post(`/${id}/invited/resend_all`, {}).then(data => {
      return data
    })
  }

  resendInvitePending(id: Number) {
    return this.post(`/${id}/invited/resend_pending`, {}).then(data => {
      return data
    })
  }

  getThemesConfig(id: Number) {
    return this.get(`/${id}/questions/themes`)
  }
}
