<template>
  <div>
    <Grid
      route_grid_path="pending"
      route_name="pending"
      order_column="name"
      :hide_searchbar="true"
      :service="service"
      @grid-UPDATED="gridChanged"
    >
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('Name') }}</vs-th>
        <vs-th key="published_at" sort-key="published_at" >{{ $t('data-publicacao') }}</vs-th>
        <vs-th key="start_at" sort-key="start_at">{{ $t('data-inicio-0') }}</vs-th>
        <vs-th key="finish_at" sort-key="finish_at">{{ $t('data-termino') }}</vs-th>
      </template>
      <template v-slot:gridActions>
        <vs-th key="actions">{{ $t('Actions') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">{{ tr.id }}</vs-td>
          <vs-td width="35%" key="name">{{ tr.name }}</vs-td>
          <vs-td width="15%" key="published_at">{{tr.published_at_formatted}}</vs-td>
          <vs-td width="15%" key="start_at">{{ tr.start_at_formatted }}</vs-td>
          <vs-td width="15%" key="finish_at">{{tr.finish_at_formatted}}</vs-td>
          <vs-td width="10%">
              <feather-icon
                icon="PlayIcon"
                svgClasses="h-5 w-5 mb-1 cursor-pointer stroke-current text-success"
                @click="openQuestionnaire(tr.short_url_id)"
                v-tooltip="'Abrir questionário'"
              />
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'

export default {

  props : {
    injectService: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    service: null,
  }),

  methods: {
    gridChanged(response) {
      this.$emit('pendingCount-changed', response.model.total)
    },
    openQuestionnaire(short_url_id) {
      const routeData = this.$router.resolve({
        name: 'questionnaires_form_answers',
        params: { short_url_id: short_url_id },
      })
      this.$vs.loading.close()
      if (!this.isElectron()) {
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push(`/form/${short_url_id}`)
      }
    },
  },

  created() {
    this.service = (this.injectService === null) ?
      this.service = ContentQuestionnaireService.build(this.$vs) :
      this.service = this.injectService
  }
}
</script>

<style>

</style>
