
<template>
  <div>
    <vx-card
      :title="$t('questionarios-pendentes')"
      class="mb-4"
      collapse-action
    >
      <div class="grid">
        <label class="font-bold justify-self-center text-warning" v-if="!showPendingQuestionnaires"> {{ $t('voce-ainda-nao-possui-nenhum-questionario-pendente') }} </label>
      </div>
      <PendingQuestionnaires
        v-if="showPendingQuestionnaires"
        @pendingCount-changed="pendingChanged"
      >
      </PendingQuestionnaires>
    </vx-card>
    <vx-card
      :title="$t('resultados')"
      class="mb-4"
      collapse-action
      ref="results"
    >
      <Grid
        ref="answersGrid"
        :service="service"
        route_name="questionnaires_answers"
        order_column="finished_at"
        direction="desc"
        :fetchOnMounted="false"
      >
        <template v-slot:gridThead>
          <vs-th key="id" sort-key="id">ID</vs-th>
          <vs-th key="name" sort-key="name">{{ $t('Questionnaire') }}</vs-th>
          <vs-th key="status" sort-key="score_released_at">{{ $t('situacao') }}</vs-th>
          <vs-th key="published_at" sort-key="finished_at"
            >{{ $t('data-finalizacao-0') }}</vs-th
          >
        </template>
        <template v-slot:gridActions>
          <vs-th key="actions" colspan="3">{{ $t('Actions') }}</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td key="id">{{ tr.id }}</vs-td>
            <vs-td key="name">
              {{ tr.content_questionnaire ? tr.content_questionnaire.name : '' }}
            </vs-td>
            <vs-td key="status">
              {{
                (tr.content_questionnaire && (tr.content_questionnaire.score_released_at || tr.content_questionnaire.view_results))  ?
                $t('resultado-liberacao-0')  :
                $t('aguardando-liberacao-0')
              }}
            </vs-td>
            <vs-td key="finish_at">
              {{
                tr.finished_at !== null
                  ? tr.finished_at_formatted
                  : $t('nao-finalizado')
              }}
            </vs-td>
            <vs-td>
              <feather-icon
                v-if="tr.finished_at === null"
                icon="PlayIcon"
                svgClasses="h-5 w-5 mb-1 cursor-pointer stroke-current text-success"
                @click="continueAnswer(tr.questionnaire_id)"
                v-tooltip="$t('continuar-questionario')"
              />
              <feather-icon
                v-else
                icon="EyeIcon"
                svgClasses="h-5 w-5 mb-1 cursor-pointer stroke-current text-primary"
                @click="showResults(tr.questionnaire_id, tr.id)"
                :v-tooltip="$t('visualizar-resultado')"
              />
            </vs-td>
          </vs-tr>
        </template>
      </Grid>
    </vx-card>
  </div>
</template>

<script>
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import PendingQuestionnaires from './PendingQuestionnaires'

export default {
  data: () => ({
    service: null,
    contentQuestionnaireService: null,
    showPendingQuestionnaires: true,
  }),
  components: {
    PendingQuestionnaires
  },
  methods: {
    continueAnswer(questionnaireId) {
      this.$vs.loading()
      const printWindowRef = window.open()
      this.contentQuestionnaireService
        .getContent(questionnaireId)
        .then((response) => {
          const routeData = this.$router.resolve({
            name: 'questionnaires_form_answers',
            params: { short_url_id: response.short_url_id },
          })
          printWindowRef.location = routeData.href
          this.$vs.loading.close()
        })


    },
    pendingChanged(many) {
      this.$refs['answersGrid'].fetchGridData()
      this.devLog('answers', 'Unlocking results grid!')
      this.hideQuestionnaire(many)
    },
    hideQuestionnaire(pendingCount) {
      if (pendingCount === 0) {
        this.showPendingQuestionnaires = false
      } else {
        // this.$refs.results.toggleContent()
      }
    },
    showResults(questionnaireId, answerId) {
      this.$vs.loading()
      this.contentQuestionnaireService
        .getContent(questionnaireId)
        .then((response) => {
          this.$router.push(`/form/${response.short_url_id}/results/${answerId}`)
        })
    },
  },
  beforeMount() {
    this.service = QuestionnaireAnswerService.build(this.$vs)
    this.contentQuestionnaireService = ContentQuestionnaireService.build(
      this.$vs
    )
  }
}
</script>

<style>
.vs-tooltip {
  z-index: 99999 !important;
}
</style>
