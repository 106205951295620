var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        attrs: {
          route_grid_path: "pending",
          route_name: "pending",
          order_column: "name",
          hide_searchbar: true,
          service: _vm.service,
        },
        on: { "grid-UPDATED": _vm.gridChanged },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("Name"))),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "published_at",
                    attrs: { "sort-key": "published_at" },
                  },
                  [_vm._v(_vm._s(_vm.$t("data-publicacao")))]
                ),
                _c(
                  "vs-th",
                  { key: "start_at", attrs: { "sort-key": "start_at" } },
                  [_vm._v(_vm._s(_vm.$t("data-inicio-0")))]
                ),
                _c(
                  "vs-th",
                  { key: "finish_at", attrs: { "sort-key": "finish_at" } },
                  [_vm._v(_vm._s(_vm.$t("data-termino")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridActions",
            fn: function () {
              return [
                _c("vs-th", { key: "actions" }, [
                  _vm._v(_vm._s(_vm.$t("Actions"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _vm._v(_vm._s(tr.id)),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "35%" } }, [
                      _vm._v(_vm._s(tr.name)),
                    ]),
                    _c(
                      "vs-td",
                      { key: "published_at", attrs: { width: "15%" } },
                      [_vm._v(_vm._s(tr.published_at_formatted))]
                    ),
                    _c("vs-td", { key: "start_at", attrs: { width: "15%" } }, [
                      _vm._v(_vm._s(tr.start_at_formatted)),
                    ]),
                    _c("vs-td", { key: "finish_at", attrs: { width: "15%" } }, [
                      _vm._v(_vm._s(tr.finish_at_formatted)),
                    ]),
                    _c(
                      "vs-td",
                      { attrs: { width: "10%" } },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Abrir questionário",
                              expression: "'Abrir questionário'",
                            },
                          ],
                          attrs: {
                            icon: "PlayIcon",
                            svgClasses:
                              "h-5 w-5 mb-1 cursor-pointer stroke-current text-success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openQuestionnaire(tr.short_url_id)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }